import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {NavLink} from "react-router-dom";
import * as React from "react";
import {Alert, Button, FormControl, FormHelperText, Grow} from "@mui/material";
import {useState} from "react";
import {useUser} from "./UserProvider";
import {TransitionGroup} from "react-transition-group";
import {LoadingButton} from "@mui/lab";
import {ArrowForwardOutlined} from "@mui/icons-material";
import {Page} from "../app/Page";
import {logError} from "../utils/logging";
import {getDefaultErrorsMapper, useErrors} from "../hooks/useErrors";
import AppConsts from "../app/AppConsts";
import {PathsMap} from "../app/PageRouting";
import {BubblesLayout} from "../components/BubblesBackground";
import {useTranslation} from 'react-i18next';
import {httpClient} from "../utils/httpClient";


export function SignUp() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const user = useUser();
    const {errors, setErrors, resetErrors} = useErrors({
        mapper: getDefaultErrorsMapper(['email', 'password', 'firstName', 'lastName'])
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (!data.get('terms')) {
            setErrors([
                {
                    key: 'terms',
                    message: t('sign_up.please_accept_the_terms_of'),
                    clientError: true
                }
            ]);
            return;
        }

        setLoading(true);

        httpClient.post(`${AppConsts.API_PATH}/users/auth/signup`, {
            email: data.get('email'),
            password: data.get('password'),
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
        }).then(response => {
            setLoading(false);

            if (response.data.errors) {
                setErrors(response.data.errors);
            } else {
                setErrors([]);
                setUserData(response.data.user);
                user.set(response.data.user, {
                    accessToken: response.data.accessToken,
                    refreshToken: response.data.refreshToken,
                    expiration: response.data.expiration
                });
            }
        }).catch((e) => {
            setLoading(false);
            logError(e);
        });
    };

    return (
        <Page title={t('sign_up.sign_up_title')} noHeader noContainer>
            <BubblesLayout>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h2" sx={{mb: 4}}>
                        {t('sign_up.sign_up_title')}
                    </Typography>
                    {userData ?
                        <>
                            <Alert severity="success">
                                {t('user_settings.your_email_address_must_be_verified', {email: userData.email})}
                            </Alert>
                            <Button
                                fullWidth
                                startIcon={<ArrowForwardOutlined/>}
                                color="success"
                                variant="outlined"
                                sx={{mt: 2}}
                                to={PathsMap.HOME} component={NavLink}>
                                {t('common.continue_to_page', {page: AppConsts.NAME})}
                            </Button>
                        </>
                    :
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{width: 1}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label={t('user.first_name')}
                                        autoFocus
                                        error={!!errors?.firstName}
                                        helperText={errors?.firstName}
                                        onChange={() => resetErrors('firstName')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        label={t('user.last_name')}
                                        name="lastName"
                                        autoComplete="family-name"
                                        error={!!errors?.lastName}
                                        helperText={errors?.lastName}
                                        onChange={() => resetErrors('lastName')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label={t('user.email_address')}
                                        name="email"
                                        autoComplete="email"
                                        error={!!errors?.email}
                                        helperText={errors?.email}
                                        onChange={() => resetErrors('email')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label={t('user.password')}
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        error={!!errors?.password}
                                        helperText={errors?.password}
                                        onChange={() => resetErrors('password')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl error={!!errors?.terms}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="terms"
                                                    value="yes"
                                                    color="primary"
                                                    onChange={() => resetErrors('terms')}
                                                />}
                                            label={t('sign_up.i_agree_to_services', {app: AppConsts.NAME})}
                                        />
                                        <FormHelperText>{errors?.terms}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <TransitionGroup>
                                {errors?.main && errors.main.map((error, errorKey) =>
                                    <Grow in={true} key={errorKey}>
                                        <Alert severity="error" sx={{mt: 2}}>
                                            {error}
                                        </Alert>
                                    </Grow>)}
                            </TransitionGroup>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                loading={loading}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {t('sign_up.sign_up_button')}
                            </LoadingButton>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link to={PathsMap.SIGN_IN} component={NavLink} variant="body2">
                                        {t('sign_up.already_have_an_account_question_sign_in')}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Box>
            </BubblesLayout>
        </Page>
    );
}