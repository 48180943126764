import Box from "@mui/material/Box";
import {Alert, CircularProgress, FormControl, FormHelperText, Grow, MenuItem, Select, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {LoadingButton} from "@mui/lab";
import AppConsts from "../app/AppConsts";
import {logError} from "../utils/logging";
import {TransitionGroup} from "react-transition-group";
import {getDefaultErrorsMapper, useErrors} from "../hooks/useErrors";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {SaveOutlined} from "@mui/icons-material";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";
import {DatePicker} from "@mui/x-date-pickers";
import {LocationsAutocomplete} from "../components/LocationsAutocomplete";
import {useProfileData} from "./UserSettings";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useSnackbar} from "../providers/SnackbarProvider";
import {toISODate} from "../utils/date";

const CURRENT_YEAR = new Date().getFullYear();
const isFutureYear = (date) => date.getFullYear() > CURRENT_YEAR;

export function UserSettingsProfile() {
    const {profileData, loadProfileData} = useProfileData();
    const {createSnackbar} = useSnackbar();
    const [formState, setFormState] = useState('none');
    const {errors, setErrors, resetErrors} = useErrors({
        mapper: getDefaultErrorsMapper([
            'firstName', 'lastName', 'genre', 'birthDate', 'fiscalCode',
            'birthLocation', 'residenceLocation', 'residenceAddress', 'domicileLocation', 'domicileAddress'
        ])
    });
    const [birthDate, setBirthDate] = useState(null);
    const [genre, setGenre] = useState('');
    const [birthLocation, setBirthLocation] = useState(null);
    const [domicileLocation, setDomicileLocation] = useState(null);
    const [residenceLocation, setResidenceLocation] = useState(null);
    const [domicileSameAsResidence, setDomicileSameAsResidence] = useState(false);

    useEffect(() => {
        if (!profileData) {
            return;
        }
        setBirthDate(profileData.birthDate ? new Date(profileData.birthDate) : null);
        setGenre(profileData.genre ?? '');
        setBirthLocation(profileData.birthLocation);
        setDomicileLocation(profileData.domicileLocation);
        setResidenceLocation(profileData.residenceLocation);
        setDomicileSameAsResidence(profileData.domicileLocation && profileData.residenceLocation
            && profileData.domicileLocation.id === profileData.residenceLocation.id
            && profileData.domicileAddress === profileData.residenceAddress);
    }, [profileData]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setFormState('loading');

        const data = new FormData(event.currentTarget);
        httpClient.post(`${AppConsts.API_PATH}/users/edit`, {
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            genre: genre,
            birthDate: birthDate ? toISODate(birthDate) : '',
            fiscalCode: data.get('fiscalCode'),
            birthLocation: birthLocation?.id ?? '',
            residenceLocation: residenceLocation?.id ?? '',
            residenceAddress: data.get('residenceAddress'),
            domicileLocation: domicileSameAsResidence ? (residenceLocation?.id ?? '') : (domicileLocation?.id ?? ''),
            domicileAddress: domicileSameAsResidence ? data.get('residenceAddress') : data.get('domicileAddress')
        }).then(response => {
            if (response.data.errors) {
                setErrors(response.data.errors);
                setFormState('unsaved');
            } else {
                setErrors([]);
                setFormState('saved');
                createSnackbar({
                    message: t('common.changes_saved_successfully'),
                    autoHideDuration: 4000,
                    type: 'success'
                });
                loadProfileData();
            }
        }).catch((e) => {
            logError(e);
            setFormState('unsaved');
        });
    };

    if (!profileData) {
        return <Box><CircularProgress/></Box>
    }

    return (
        <Box>
            <Typography variant="h5" sx={{mb: 4}}>
                {t('user_settings.edit_profile')}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{width: 1, maxWidth: 720}}>
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" color={!!errors?.firstName ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.first_name')} *
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            required
                            name="firstName"
                            defaultValue={profileData.firstName}
                            error={!!errors?.firstName}
                            helperText={errors?.firstName}
                            onChange={() => resetErrors('firstName')}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={4} >
                        <Typography variant="h6" color={!!errors?.lastName ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.last_name')} *
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            required
                            name="lastName"
                            defaultValue={profileData.lastName}
                            error={!!errors?.lastName}
                            helperText={errors?.lastName}
                            onChange={() => resetErrors('lastName')}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={4} >
                        <Typography variant="h6" color={!!errors?.lastName ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.genre')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth error={!!errors?.genre}>
                            <Select
                                displayEmpty
                                name="genre"
                                onChange={e => setGenre(e.target.value)}
                                value={genre}>
                                <MenuItem value="">
                                    <em>nd</em>
                                </MenuItem>
                                <MenuItem value="MALE">{t('user.genre_names.MALE')}</MenuItem>
                                <MenuItem value="FEMALE">{t('user.genre_names.FEMALE')}</MenuItem>
                            </Select>
                            {errors?.genre && <FormHelperText>{errors.genre}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={4} >
                        <Typography variant="h6" color={!!errors?.lastName ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.birth_date')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <DatePicker
                            name="birthDate"
                            openTo="year"
                            views={['year', 'month', 'day']}
                            value={birthDate}
                            shouldDisableYear={isFutureYear}
                            onChange={setBirthDate}
                            slotProps={{textField: {
                                    error: !!errors?.birthDate,
                                    helperText: errors?.birthDate
                                }}} />
                    </Grid>
                </Grid>
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={4} >
                        <Typography variant="h6" color={!!errors?.birthLocation ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.birth_location')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <LocationsAutocomplete
                            error={!!errors?.birthLocation}
                            helperText={errors?.birthLocation}
                            onChange={setBirthLocation}
                            value={birthLocation} />
                    </Grid>
                </Grid>
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={4} >
                        <Typography variant="h6" color={!!errors?.fiscalCode ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.fiscal_code')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            required
                            name="fiscalCode"
                            defaultValue={profileData.fiscalCode}
                            error={!!errors?.fiscalCode}
                            helperText={errors?.fiscalCode}
                            placeholder={"0000000000000000"}
                            onChange={() => resetErrors('fiscalCode')}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{width: 1, mb: 4}} rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={4} >
                        <Typography variant="h6" color={!!errors?.residenceLocation ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.residence')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <LocationsAutocomplete
                            error={!!errors?.residenceLocation}
                            helperText={errors?.residenceLocation}
                            onChange={setResidenceLocation}
                            value={residenceLocation} />
                        <TextField
                            fullWidth
                            name="residenceAddress"
                            defaultValue={profileData.residenceAddress}
                            sx={{mt: 2}}
                            label={t('user.address')}
                            error={!!errors?.residenceAddress}
                            helperText={errors?.residenceAddress}
                            onChange={() => resetErrors('residenceAddress')} />
                    </Grid>
                    {!!residenceLocation && <>
                        <Grid item xs={0} md={4}>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControl sx={{mt: 2}}>
                                <FormControlLabel
                                    value="1"
                                    checked={domicileSameAsResidence}
                                    onChange={(e) => setDomicileSameAsResidence(e.target.checked)}
                                    control={<Checkbox color="primary"  />}
                                    label={t('user_settings.corresponds_to_domicile')}
                                />
                            </FormControl>
                        </Grid>
                    </>}
                </Grid>
                <Grid container sx={{width: 1, mb: 4, display: domicileSameAsResidence ? 'none' : ''}}
                      rowSpacing={{xs: 2, md: 0}} columnSpacing={{md: 4}} alignItems="center">
                    <Grid item xs={12} md={4} >
                        <Typography variant="h6" color={!!errors?.domicileLocation ? 'error' : ''} sx={{textAlign: {md: 'right'}}}>
                            {t('user.domicile')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <LocationsAutocomplete
                            error={!!errors?.domicileLocation}
                            helperText={errors?.domicileLocation}
                            onChange={setDomicileLocation}
                            value={domicileLocation} />
                        <TextField
                            fullWidth
                            name="domicileAddress"
                            defaultValue={profileData.domicileAddress}
                            sx={{mt: 2}}
                            label={t('user.address')}
                            error={!!errors?.domicileAddress}
                            helperText={errors?.domicileAddress}
                            onChange={() => resetErrors('domicileAddress')} />
                    </Grid>
                </Grid>
                <TransitionGroup>
                    {errors?.main && errors.main.map((error, errorKey) =>
                        <Grow in={true} key={errorKey}>
                            <Alert severity="error" sx={{mt: 2}}>
                                {error}
                            </Alert>
                        </Grow>)}
                </TransitionGroup>
                <LoadingButton
                    size="large"
                    startIcon={<SaveOutlined />}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    loading={formState === 'loading'}
                >
                    {t('common.save')}
                </LoadingButton>
            </Box>
        </Box>
    );
}