import React, {useCallback, useEffect, useRef, useState} from "react";
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import AppConsts from "../app/AppConsts";
import {httpClient} from "../utils/httpClient";

const STRG_ACCESS_TOKEN_NAME = 'auth_access';
const STRG_REFRESH_TOKEN_NAME = 'auth_refresh';
const STRG_REFRESH_INTERVAL_SECONDS = 60 * 5; // 5 minutes

export const UserContext = React.createContext(null);

export const useUser = () => {
    return React.useContext(UserContext);
};

export default function UserProvider({children}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [logged, setLogged] = useState(false);
    const refreshTokenTimeout = useRef(null);

    const getAccessToken = useCallback(() => {
        return localStorage.getItem(STRG_ACCESS_TOKEN_NAME);
    }, []);

    const deleteUserLocalStorage = useCallback(() => {
        localStorage.removeItem(STRG_ACCESS_TOKEN_NAME);
        localStorage.removeItem(STRG_REFRESH_TOKEN_NAME);
    }, []);

    const executeTokenRefresh = useCallback(() => {
        return new Promise((success, fail) => {
            const refreshTokenStorage = localStorage.getItem(STRG_REFRESH_TOKEN_NAME);
            if (typeof refreshTokenStorage === 'string' && refreshTokenStorage.length) {
                httpClient.withoutInterceptors.post(`${AppConsts.API_PATH}/users/auth/refresh`, {
                    refreshToken: refreshTokenStorage
                }).then(response => {
                    if (response.data.accessToken && response.data.refreshToken && response.data.user) {
                        success({
                            userData: response.data.user,
                            storageData: {
                                accessToken: response.data.accessToken,
                                refreshToken: response.data.refreshToken,
                                expiration: response.data.expiration
                            }
                        });
                    } else {
                        fail();
                    }
                }).catch(() => {
                    fail();
                });
            } else {
                fail();
            }
        });
    }, []);

    const getCurrentUser = useCallback(() => {
        return new Promise((success, fail) => {
            const accessTokenStorage = localStorage.getItem(STRG_ACCESS_TOKEN_NAME);

            if (typeof accessTokenStorage === 'string' && accessTokenStorage.length) {
                httpClient.get(`${AppConsts.API_PATH}/users/auth`).then(response => {
                    if (response.data.user && response.data.expiration) {
                        if (new Date().getTime() / 1000 > response.data.expiration - STRG_REFRESH_INTERVAL_SECONDS * 2) {
                            fail(true);
                        } else {
                            success(response.data.user);
                        }
                    } else {
                        fail(false);
                    }
                }).catch((e) => {
                    fail(e.response.status === 401);
                });
            } else {
                fail(false);
            }
        });
    }, []);

    const setUser = useCallback((newUserData, newStorageData) => {
        clearTimeout(refreshTokenTimeout.current);

        if (!newUserData) {
            setLogged(false);
            setData(null);
            deleteUserLocalStorage();
            return;
        }

        setData((currentUserData) => {
            if (newStorageData) {
                localStorage.setItem(STRG_ACCESS_TOKEN_NAME, newStorageData.accessToken);
                localStorage.setItem(STRG_REFRESH_TOKEN_NAME, newStorageData.refreshToken);
            }

            refreshTokenTimeout.current = setTimeout(() => {
                executeTokenRefresh()
                    .then(res => setUser(res.userData, res.storageData))
                    .catch(() => {});
            }, STRG_REFRESH_INTERVAL_SECONDS * 1000);

            if (currentUserData !== null && currentUserData.id !== newUserData.id) {
                setTimeout(() => window.location.reload(), 0); // user change requires page reload
            }

            return newUserData;
        });

        setLogged(true);
    }, [deleteUserLocalStorage, executeTokenRefresh]);

    const updateUser = useCallback((newUserData) => {
        setData((currentUserData) => {
            if (currentUserData === null || ('id' in newUserData && currentUserData.id !== newUserData.id)) {
                return; // user change not allowed (probably by another tab), stop
            }
            return Object.assign({}, currentUserData, newUserData);
        });
    }, []);

    useEffect(() => {
        httpClient.interceptors.request.use(function (config) {
            const accessToken = localStorage.getItem(STRG_ACCESS_TOKEN_NAME);
            if (accessToken) {
                config.headers.Authorization = 'Bearer ' + accessToken;
            }
            return config;
        });

        httpClient.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response && error.response.status === 401) {
                deleteUserLocalStorage();
            } else {
                return Promise.reject(error);
            }
        });

        getCurrentUser().then(userData => {
            setUser(userData, null);
            setLoading(false);
        }).catch(refreshRequired => {
            if (refreshRequired) {
                executeTokenRefresh()
                    .then(res => {
                        setUser(res.userData, res.storageData);
                        setLoading(false);
                    })
                    .catch(() => setLoading(false));
            } else {
                setLoading(false);
            }
        });
    }, [getCurrentUser, deleteUserLocalStorage, executeTokenRefresh, setUser]);

    return (
        <UserContext.Provider value={{set: setUser, update: updateUser, getAccessToken, data, logged}}>
            {loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <CircularProgress/>
                </Box>
            ) : children}
        </UserContext.Provider>
    );
}