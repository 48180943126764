
export function debounce(event, fn) {
    if (!event.target) {
        fn();
        return;
    }
    const target = event.target;

    let delay = 150;
    if (target._debounceTimeout) {
        clearTimeout(target._debounceTimeout);
        target._debounceTimeout = null;
        delay = 500;
    }
    target._debounceTimeout = setTimeout(() => {
        target._debounceTimeout = null;
        fn();
    }, delay);

    if (!('_debounceBlurAttached' in target)) {
        target._debounceBlurAttached = true;
        event.target.addEventListener('blur', () => {
            if (target._debounceTimeout !== null) {
                clearTimeout(target._debounceTimeout);
                target._debounceTimeout = null;
                fn();
            }
        });
    }
}