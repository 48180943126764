import axios from "axios";

export const httpClient = {
    __handlePromise: (fn, args) => {
        return new Promise((resolve, reject) => {
            fn(...args)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (error.response?.status === 400) {
                        resolve(error.response);
                    } else {
                        reject(error);
                    }
                });
        });
    },
    post: (url, data, config) => {
        return httpClient.__handlePromise(axios.post, [url, data, config]);
    },
    put: (url, data, config) => {
        return httpClient.__handlePromise(axios.put, [url, data, config]);
    },
    get: (url, config) => {
        return httpClient.__handlePromise(axios.get, [url, config]);
    },
    delete: (url, config) => {
        return httpClient.__handlePromise(axios.delete, [url, config]);
    },
    cancelToken: () => axios.CancelToken.source(),
    interceptors: {
        request: {
            use: (fn) =>  axios.interceptors.request.use(fn)
        },
        response: {
            use: (fn) =>  axios.interceptors.response.use(fn)
        }
    },
    withoutInterceptors: {
        post: (url, data, config) => {
            return httpClient.__handlePromise(axios.create().post, [url, data, config]);
        },
        put: (url, data, config) => {
            return httpClient.__handlePromise(axios.create().put, [url, data, config]);
        },
        get: (url, config) => {
            return httpClient.__handlePromise(axios.create().get, [url, config]);
        },
        delete: (url, config) => {
            return httpClient.__handlePromise(axios.create().delete, [url, config]);
        },
    }
};

export class handleAlways {
}