import React, {useState} from "react";
import Button from "@mui/material/Button";
import {Box, Breadcrumbs, Stack, Tabs, Typography} from "@mui/material";
import {AddOutlined, EditOutlined, ReplyOutlined} from "@mui/icons-material";
import {Page} from "../app/Page";
import {useFormCreateDialog} from "../form-create/FormCreateDialog";
import {FormsManagedCardList} from "./FormsManagedCardList";
import {useUser} from "../user-auth/UserProvider";
import {UserRequiredRedirect} from "../user-auth/UserRequiredRedirect";
import AppConsts from "../app/AppConsts";
import {t} from "i18next";
import Tab from "@mui/material/Tab";
import {FormsCardList} from "./FormsCardList";

export function FormsPageMiddleware() {
    const user = useUser();
    if (!user.logged) {
        return <UserRequiredRedirect />;
    }
    return <FormsPage />;
}

function FormsPage() {
    const [openCreateFormDialog] = useFormCreateDialog();
    const user = useUser();
    const avaibleTabs = [];
    if (user.data.canManageForms) {
        avaibleTabs.push('dashboardForms');
    }
    avaibleTabs.push('userForms');
    const [currentTab, setCurrentTab] = useState(avaibleTabs.length ? avaibleTabs[0] : null);

    return (
        <Page>
            <Breadcrumbs sx={{mb: 4}}>
                <Typography color="text.primary">
                    {AppConsts.NAME}
                </Typography>
            </Breadcrumbs>

            <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 4}}>
                <Tabs value={currentTab} centered>
                    {avaibleTabs.includes('dashboardForms') &&
                        <Tab
                            onClick={() => setCurrentTab('dashboardForms')}
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <EditOutlined fontSize="small"/>
                                    <Typography>{t('forms_list.managed_forms')}</Typography>
                                </Stack>}
                            value={'dashboardForms'} />}
                    {avaibleTabs.includes('userForms') &&
                        <Tab
                            onClick={() => setCurrentTab('userForms')}
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <ReplyOutlined fontSize="small"/>
                                    <Typography>{t('forms_list.fillabe_forms')}</Typography>
                                </Stack>}
                            value={'userForms'} />}
                </Tabs>
            </Box>
            {currentTab === 'dashboardForms' && <>
                <Box sx={{mb: 4}}>
                    <Button variant="contained" startIcon={<AddOutlined/>} onClick={openCreateFormDialog}>
                        {t('new_form.new_form_button')}
                    </Button>
                </Box>
                <FormsManagedCardList />
            </>}
            {currentTab === 'userForms' &&
                <FormsCardList />
            }
        </Page>
    );
}