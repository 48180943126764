import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Alert,
    Box, Checkbox, Chip, DialogActions, DialogContent, DialogContentText,
    DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, Select,
    Stack, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography
} from "@mui/material";
import {
    AddOutlined,
    ArrowBackOutlined,
    CheckBoxOutlineBlank, DeleteOutlined,
    EditOutlined,
    PanToolOutlined, QuestionAnswerOutlined, RadioButtonUncheckedOutlined,
    RemoveOutlined, TopicOutlined
} from "@mui/icons-material";
import {ICON_STYLES, Theme} from "../../app/Theme";
import {QUESTION} from "../../utils/formUtils";
import {DialogWrapper} from "../../components/DialogWrapper";
import {computeElementWidth} from "../../utils/element";
import cytoscape from "cytoscape";
import edgehandles from "cytoscape-edgehandles";
import cxtmenu from "cytoscape-cxtmenu";
import useIdGenerator from "../../hooks/useIdGenerator";
import Button from "@mui/material/Button";
import {useDialog} from "../../providers/DialogProvider";
import {parseIntNumber} from "../../utils/numbers";
import Tab from "@mui/material/Tab";
import {t} from "i18next";

cytoscape.use(edgehandles);
cytoscape.use(cxtmenu);
cytoscape.warnings(false);

export function useQuizSettingsDialog(form, managers, errors, errorsManager) {
    return DialogWrapper({
        component: QuizSettingsDialog,
        componentProps: {form: form, managers: managers, errors: errors, errorsManager: errorsManager},
        maxWidth: 'md',
        fullWidth: true
    });
}

function QuizSettingsDialog({form, managers, onClose, errors, errorsManager}) {
    const [currentTab, setCurrentTab] = useState("questions");

    const handleTabChange = (event, newTab) => {
        setCurrentTab(newTab);
    };

    const goToTopics = () => {
        setCurrentTab("topics");
    };

    return <>
        <DialogTitle>
            <Stack direction="row" alignItems="center">
                <IconButton onClick={onClose} sx={{mr: 1}}>
                    <ArrowBackOutlined/>
                </IconButton>
                {t('form_editor.edit_score_quiz')}
            </Stack>
        </DialogTitle>
        <DialogContent>
            {(errors?.['topics'] || []).map((error, errorKey) =>
                <Alert severity="error" key={errorKey} sx={{mb: 1}}>{error}</Alert>)}
            <Tabs value={currentTab} onChange={handleTabChange} centered sx={{mb: 4}}>
                <Tab
                    label={
                        <Stack direction="row" spacing={1} alignItems="center">
                            <QuestionAnswerOutlined fontSize="small"/>
                            <Typography>{t("form.questions")}</Typography>
                        </Stack>}
                    value="questions" />
                <Tab
                    label={
                        <Stack direction="row" spacing={1} alignItems="center">
                            <TopicOutlined fontSize="small"/>
                            <Typography>{t("form.topics")}</Typography>
                        </Stack>}
                    value="topics" />
            </Tabs>
            <Box hidden={currentTab !== "topics"}>
                <QuizTopics
                    topics={form.topics}
                    questions={form.questions}
                    managers={managers} />
            </Box>
            <Box hidden={currentTab !== "questions"}>
                <QuizQuestions
                    form={form}
                    managers={managers}
                    errors={errors}
                    errorsManager={errorsManager}
                    goToTopics={goToTopics} />
            </Box>
        </DialogContent>
    </>;
}

function calculateQuestionMaxScore(q) {
    if (q.type === QUESTION.TYPES.SINGLE_CHOICE) {
        return Math.max(...q.choices.map(o => o.score));
    }
    let value = 0;
    if (q.choices) {
        q.choices.forEach(c => {
            if (c.score > 0) {
                value += c.score;
            }
        });
    }
    return value;
}

function QuizQuestions({form, managers, errors, errorsManager, goToTopics}) {
    const maxScore = useMemo(() => {
        let value = 0;
        form.questions.forEach(q => value += calculateQuestionMaxScore(q));
        return value;
    }, [form.questions]);

    return <>
        {form.questions.map(question =>
            <QuizQuestion
                topics={form.topics}
                question={question}
                managers={managers}
                errors={errors}
                errorsManager={errorsManager}
                goToTopics={goToTopics}
                key={question.ref} />)}
        <Stack direction="row" spacing={1} alignItems="center" sx={{mb: 3}}>
            <Typography variant="h3">{t('form_editor.max_score')}:</Typography>
            <Typography variant="body" color="text.primary">{maxScore}</Typography>
        </Stack>
    </>
}


function QuizResultTables({form}) {
    return (
        <>
            <Typography variant="h2" sx={{mb: 3}}>__RESULT_TABLES__</Typography>
            {form.quizResultTables.map((tab, index) =>
                <QuizResultTable key={index} tab={tab}></QuizResultTable>)}
        </>
    );
}

function QuizResultTable({tab}) {
    return (
        <Paper variant="outlined" sx={{mb: 3, p: 3}}>
            <Box sx={{mb: 3}}>
                <TextField
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={tab.title}
                    error={!tab.title}
                    onChange={(e) => {
                        //setValue(parseIntNumber(e.target.value));
                    }}
                />
            </Box>

            <Box sx={{mb: 3}}>
                <Typography variant="h3" sx={{mb: 3}}>__COLUMNS__</Typography>
                {tab.columns.map((col, colIndex) => (
                    <Box key={colIndex}>
                        <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={col}
                                error={!col}
                                onChange={(e) => {
                                    //setValue(parseIntNumber(e.target.value));
                                }}
                    />
                    </Box>))}
            </Box>

            <Typography variant="h3" sx={{mb: 3}}>__CLASSES__</Typography>
            {tab.classes.map((tabClass, tabClassIndex) =>
                <Box sx={{mb: 3}} key={tabClassIndex}>
                    {tabClass.columns.map((col, colIndex) => (
                        <Stack direction="row" spacing={3} sx={{mb: 1}} key={colIndex}>
                            <Box sx={{width: 200}}>{tab.columns[colIndex]}</Box>
                            <TextField
                                autoFocus
                                margin="dense"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={col}
                                error={!col}
                                onChange={(e) => {
                                    //setValue(parseIntNumber(e.target.value));
                                }}
                            />
                        </Stack>))}
                </Box>)}
        </Paper>
    );
}

function QuizQuestion({topics, question, managers, errors, errorsManager, goToTopics}) {
    const maxScore = useMemo(() => calculateQuestionMaxScore(question), [question]);

    if (question.type !== QUESTION.TYPES.SINGLE_CHOICE && question.type !== QUESTION.TYPES.MULTIPLE_CHOICE) {
        return (
            <Paper variant="outlined" sx={{mb: 3, p: 3}}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Chip variant="outlined" color="primary" label={question.position} sx={{fontWeight: 500, fontSize: 18}} />
                    <Typography variant="h2">{question.title}</Typography>
                </Stack>
                <Typography color="text.secondary" sx={{mt: 2}}>
                    {t("form_editor.topics_scoring_online_choice_questions")}
                </Typography>
            </Paper>);
    }

    const errorsPrefix = `questionsQuiz[${question.ref}]`;
    const questionsManager = managers.questions;

    return (
        <Paper variant="outlined" sx={{mb: 3, p: 3}}>
            <Box sx={{mb: 3}}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Chip variant="outlined" color="primary" label={question.position} sx={{fontWeight: 500, fontSize: 18}} />
                    <Typography variant="h2">{question.title}</Typography>
                </Stack>
                {(errors?.[errorsPrefix] || []).map((error, errorKey) =>
                    <Alert severity="error" key={errorKey} sx={{mb: 1}}>{error}</Alert>)}
            </Box>
            <Grid container sx={{width: '100%', mb: 2}} spacing={1} columns={{xs: 2, sm: 20}} alignItems="center">
                <Grid item xs={2} sm={3}>
                    {t('form.topic')}
                </Grid>
                <Grid item xs={2} sm={17}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        {(errors?.[`${errorsPrefix}.topic`] || []).map((error, errorKey) =>
                            <Alert severity="error" key={errorKey} sx={{mb: 1}}>{error}</Alert>)}
                        <Select
                            inputProps={{MenuProps: {disableScrollLock: true}}}
                            onChange={(e) => {
                                questionsManager.editTopicRef(question.ref, e.target.value);
                            }}
                            error={!!errors?.[`${errorsPrefix}.topic`]}
                            label="Topic"
                            size="small"
                            value={question.topicRef}
                            displayEmpty>
                            <MenuItem value="" >
                                <em>- no topic -</em>
                            </MenuItem>
                            {topics.map(topic => (
                                <MenuItem value={topic.ref} key={topic.ref}>
                                    {topic.title}
                                </MenuItem>
                            ))}
                        </Select>
                        <IconButton variant="filled" onClick={() => goToTopics()}>
                            <EditOutlined sx={ICON_STYLES.icon16} />
                        </IconButton>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container sx={{width: '100%'}} spacing={1} columns={{xs: 2, sm: 20}} alignItems="center">
                <Grid item xs={2} sm={3}>
                    {t('form.scores')}
                </Grid>
                <Grid item xs={2} sm>
                    {question.choices.map(choice =>
                        <QuizChoice
                            key={choice.ref}
                            question={question}
                            choice={choice}
                            managers={managers}
                            errors={errors}
                            errorsPrefix={errorsPrefix}
                            errorsManager={errorsManager} />
                    )}
                </Grid>
            </Grid>
            {maxScore > 0 && question.type === QUESTION.TYPES.MULTIPLE_CHOICE &&
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={question.quizAllCorrectRequired}
                            onChange={() => {
                                questionsManager.editquizAllCorrectRequired(question.ref, !question.quizAllCorrectRequired);
                            }} />}
                    label={maxScore === 1
                            ? t('form_editor.1_point_only_if_all_correct')
                            : t('form_editor.n_points_only_if_all_correct', {n: maxScore})} />}
        </Paper>);
}

function QuizChoice({question, choice, managers, errors, errorsManager, errorsPrefix}) {
    const responseErrorPrefix = `${errorsPrefix}.choices[${choice.ref}]`;
    let choicesManager = managers.choices;

    return (
        <Grid container sx={{width: '100%', mb: {xs: 2, md: 1}}} columns={{xs: 2, md: 20}} spacing={{xs: 0, md: 1}} alignItems="center">
            <Grid item xs={2} md={5}>
                <TextField
                    onChange={(e) => {
                        choicesManager.editScore(question.ref, choice.ref, parseIntNumber(e.target.value));
                        errorsManager.resetErrors(`${responseErrorPrefix}.score`);
                    }}
                    error={!!errors?.[`${responseErrorPrefix}.score`]}
                    helperText={errors?.[`${responseErrorPrefix}.score`]?.join(' - ')}
                    placeholder="Score"
                    type="text"
                    value={choice.score}
                    variant="outlined"
                    size="small"
                    inputProps={{
                        style: {
                            textAlign: 'center'
                        }
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => {
                                choicesManager.editScore(question.ref, choice.ref, choice.score - 1);
                                errorsManager.resetErrors(`${responseErrorPrefix}.score`);
                            }}>
                                <RemoveOutlined sx={ICON_STYLES.icon16} />
                            </IconButton>
                        </InputAdornment>,
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => {
                                choicesManager.editScore(question.ref, choice.ref, choice.score + 1);
                                errorsManager.resetErrors(`${responseErrorPrefix}.score`);
                            }}>
                                <AddOutlined sx={ICON_STYLES.icon16} />
                            </IconButton>
                        </InputAdornment>
                    }}
                    fullWidth />
            </Grid>
            <Grid item xs={2} md={15}>
                <Stack direction="row" spacing={2} alignItems="center" sx={{
                    px: 2,
                    width: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                    {question.type === QUESTION.TYPES.MULTIPLE_CHOICE
                        ? <CheckBoxOutlineBlank sx={ICON_STYLES.icon21}/>
                        : <RadioButtonUncheckedOutlined sx={ICON_STYLES.icon21}/>}
                    <Box>{choice.title.length > 0 ? choice.title : "- empty -"}</Box>
                </Stack>
            </Grid>
        </Grid>
    );
}

function QuizTopics({questions, topics, managers}) {
    const [mode, setMode] = useState('pan');

    return <Box id="topics">
        <Paper variant="outlined">
            <Paper elevation={3}>
                <Box display="flex" justifyContent="center">
                    <ToggleButtonGroup
                        value={mode}
                        exclusive
                        onChange={(e, value) => value !== null && setMode(value)}>
                        <ToggleButton selected={mode === "pan"} value="pan" color="primary">
                            <PanToolOutlined sx={ICON_STYLES.icon21Left} />
                            Move
                        </ToggleButton>
                        <Tooltip arrow title={<div><div>• Click on any point do add a topic</div><div>• Hold mouse left click and drag a line from a topic to another to add a link</div></div>}>
                            <ToggleButton selected={mode === "add"} value="add" color="success">
                                <AddOutlined sx={ICON_STYLES.icon21Left} />
                                Add
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip arrow title={<div><div>• Click on any topic to edit its title</div><div>• Click on any edge to edit its weight</div></div>}>
                            <ToggleButton selected={mode === "edit"} value="edit" color="primary">
                                <EditOutlined sx={ICON_STYLES.icon21Left} />
                                Edit
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip arrow title={<div><div>• Click on any topic to delete it</div><div>• Click on any edge to delete it</div></div>}>
                            <ToggleButton selected={mode === "delete"} value="delete" color="error">
                                <DeleteOutlined sx={ICON_STYLES.icon21Left} />
                                Delete
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>
                </Box>
            </Paper>
            <QuizTopicsGraph
                questions={questions}
                topics={topics}
                managers={managers}
                mode={mode} />
        </Paper>
    </Box>
}

function QuizTopicsGraph(props) {
    const {generateId: generateRef} = useIdGenerator();

    const initialTopics = useRef(props.topics);
    const managers = props.managers;

    const cyApi = useRef(null);
    const containerRef = useRef(null);
    const container = useMemo(() => {
        // noinspection CheckTagEmptyBody
        return <div ref={containerRef} style={{height: '500px'}}></div>;
    }, []);

    const topicsQuestionTotals = useRef({});
    useEffect(() => {
        topicsQuestionTotals.current = {};
        props.topics.forEach(t => {
            topicsQuestionTotals.current[t.ref] = managers.topics.getTopicQuestionsTotal(props.questions, t.ref)
        });
    }, [props.topics, props.questions, managers.topics]);

    const {openDialog} = useDialog();

    const createTopicElements = useCallback((topic) => {
        const elements = [];
        let node = {
            group: 'nodes',
            data: {
                id: topic.ref,
                title: topic.title
            },
            position: {
                x: topic.position.x,
                y: topic.position.y
            }
        };
        elements.push(node);
        topic.childLinks.forEach(link => {
            elements.push({
                group: 'edges',
                data: {
                    source: topic.ref,
                    target: link.targetRef,
                    weight: link.weight
                }
            });
        });
        return [elements, node];
    }, []);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const initialElements = [];
        const presetPositions = [];

        initialTopics.current.forEach(topic => {
            const [elements, node] = createTopicElements(topic);
            presetPositions[node.data.id] = {
                x: node.position.x,
                y: node.position.y
            };
            elements.forEach(el => initialElements.push(el));
        });

        let cy = cytoscape({
            container: containerRef.current,
            elements: initialElements,
            minZoom: 0.2,
            maxZoom: 1.5,
            style: [
                {
                    selector: 'node',
                    style: {
                        'label': 'data(title)',
                        'shape': 'round-rectangle',
                        'width': (node) => {
                            const textWidth = computeElementWidth(node.data().title, {
                                fontFamily: Theme.typography.fontFamily,
                                fontSize: Theme.typography.fontSize,
                                fontWeight: Theme.typography.fontWeightMedium
                            });
                            return Math.max(textWidth, 80) + 30 + 'px';
                        },
                        'height': '42px',
                        'font-family': Theme.typography.fontFamily,
                        'font-size': Theme.typography.fontSize,
                        'font-weight': Theme.typography.fontWeightMedium,
                        'text-valign': 'center',
                        'text-halign': 'center',
                        'background-color': '#fff',
                        'border-width': '1px',
                        'border-color': '#a4b6c7',
                        'color': '#000'
                    }
                }, {
                    selector: 'edge.eh-preview',
                    style: {
                        'line-color': Theme.palette.primary.light,
                        'target-arrow-color': Theme.palette.primary.light
                    }
                }, {
                    selector: 'edge',
                    style: {
                        'width': 2,
                        'line-color': '#8a9eb0',
                        'line-style': 'dashed',
                        'line-dash-pattern': [6, 3],
                        'line-dash-offset': 0,
                        'curve-style': 'bezier',
                        'arrow-scale': 1.5,
                        'target-arrow-shape': 'triangle',
                        'target-arrow-color': '#505f6e',
                        'target-label': 'data(weight)',
                        'target-text-offset': '40px',
                        'color': '#fff',
                        'font-family': Theme.typography.fontFamily,
                        'font-size': Theme.typography.fontSize,
                        'font-weight': Theme.typography.fontWeightMedium,
                        'text-background-color': '#f44336',
                        'text-background-shape': 'round-rectangle',
                        'text-background-opacity': '1',
                        'text-background-padding': '5px'
                    }
                }, {
                    selector: 'edge[weight>=1]',
                    style: {
                        'text-background-color': '#ff9800',
                    }
                }, {
                    selector: 'edge[weight>=20]',
                    style: {
                        'text-background-color': '#ffc107',
                    }
                }, {
                    selector: 'edge[weight>=40]',
                    style: {
                        'text-background-color': '#ffeb3b',
                    }
                }, {
                    selector: 'edge[weight>=60]',
                    style: {
                        'text-background-color': '#cddc39',
                    }
                }, {
                    selector: 'edge[weight>=80]',
                    style: {
                        'text-background-color': '#8bc34a',
                    }
                }, {
                    selector: 'edge[weight>=90]',
                    style: {
                        'text-background-color': '#4caf50',
                    }
                }
            ],
            wheelSensitivity: 0.2
        });

        cy.on('dragfree', (e) => {
            if (e.target.isNode()) {
                const data = e.target.data();
                const position = e.target.position();
                managers.topics.editPosition(data.id, {
                    x: position.x,
                    y: position.y
                })
            }
        });

        const isGhost = (el) => {
            return el.hasClass('eh-ghost')
                || el.hasClass('eh-ghost-node')
                || el.hasClass('eh-ghost-edge');
        };

        cy.on('remove', (e) => {
            if (isGhost(e.target)) {
                return;
            }
            if (e.target.isNode()) {
                let data = e.target.data();
                managers.topics.delete(data.id);
            } else if (e.target.isEdge()) {
                let data = e.target.data();
                managers.topics.deleteLink(data.source, data.target);
            }
        });

        cy.on('add', (e) => {
            if (isGhost(e.target)) {
                return;
            }
            if (e.target.isEdge()) {
                let data = e.target.data();
                managers.topics.addLink(data.source, {
                    targetRef: data.target,
                    weight: data.weight
                });
            }
        });

        function editTopicTitle(node) {
            const data = node.data();

            const onSubmit = (newTitle) => {
                if (newTitle.length < 1) {
                    return;
                }
                managers.topics.editTitle(data.id, newTitle);
                node.data('title', newTitle);
            };

            openDialog({
                children: <QuizTitleDialog onSubmit={onSubmit} initialValue={data.title}/>,
                maxWidth: 'sm',
                fullWidth: true
            });
        }

        function deleteTopicWithConfirm(node) {
            const data = node.data();
            const totalQuestions = topicsQuestionTotals.current[data.id];
            if ((totalQuestions > 0 && !window.confirm(totalQuestions === 1
                    ? `1 question is associated to this topic. Are you sure you want to delete ${data.title}??`
                    : `${totalQuestions} questions are associated to this topic. Are you sure you want to delete ${data.title}??`))
                || (totalQuestions < 1 && !window.confirm(`Are you sure you want to delete ${data.title}?`))) {
                return;
            }
            cy.remove(node);
        }

        function deleteLink(edge) {
            cy.remove(edge);
        }

        // Edge commands
        const edgeCommands = [];
        edgeCommands.push({
            content: "Del",
            select: (edge) => {
                deleteLink(edge);
            }
        });
        for (let weightValue = 100; weightValue >= 0; weightValue -= 10) {
            edgeCommands.push({
                content: weightValue,
                select: (edge) => {
                    let data = edge.data();
                    managers.topics.editLinkWeight(data.source, data.target, weightValue);
                    edge.data('weight', weightValue);
                }
            });
        }

        // noinspection JSUnresolvedFunction
        cy.cxtmenu({
            selector: 'edge',
            commands: edgeCommands,
            menuRadius: 100
        });

        // noinspection JSUnresolvedFunction
        cy.cxtmenu({
            selector: 'node',
            commands: [
                {
                    content: "Delete",
                    select: (node) => {
                        deleteTopicWithConfirm(node);
                    }
                }, {
                    content: "Edit title",
                    select: (node) => {
                        // set timeout to avoid default browser context menu to appear
                        setTimeout(() => editTopicTitle(node), 150);
                    }
                }
            ],
            menuRadius: 75
        });

        // noinspection JSUnresolvedFunction
        let cyedgehandles = cy.edgehandles({
            canConnect: (sourceNode, targetNode) => {
                return !sourceNode.same(targetNode)
                    && !sourceNode.edgesWith(targetNode).length
                    && !targetNode.successors().contains(sourceNode);
            },
            edgeParams: (sourceNode, targetNode) => ({
                data: {
                    weight: 100
                }
            }),
        });

        const onClickAddTopic = (e) => {
            const onSubmit = (title) => {
                if (title.length < 1) {
                    return;
                }
                let topic = managers.topics.createNew({
                    title: title,
                    position: {
                        x: e.position.x,
                        y: e.position.y
                    }
                });
                managers.topics.add(topic);
                const [elements] = createTopicElements(topic);
                elements.forEach(el => cy.add(el));
            };

            openDialog({
                children: <QuizTitleDialog onSubmit={onSubmit} initialValue="" />,
                maxWidth: 'sm',
                fullWidth: true
            });
        };

        const onClickEditLink = (e) => {
            function editLinkWeight() {
                let data = e.target.data();

                const onSubmit = (newWeight) => {
                    managers.topics.editLinkWeight(data.source, data.target, newWeight);
                    e.target.data('weight', newWeight);
                };

                openDialog({
                    children: <QuizLinkWeightDialog onSubmit={onSubmit} initialValue={data.weight}/>,
                    maxWidth: 'sm',
                    fullWidth: true
                });
            }

            editLinkWeight();
        };

        const onClickEditTopic = (e) => {
            editTopicTitle(e.target);
        };

        const onClickDeleteLink = (e) => {
            deleteLink(e.target);
        };

        const onClickDeleteTopic = (e) => {
            deleteTopicWithConfirm(e.target);
        };

        const MODES = {
            pan: {
                enable: () => {},
                disable: () => {}
            },
            add: {
                enable: () => {
                    cy.on('click', onClickAddTopic);
                    cyedgehandles.enableDrawMode()
                },
                disable: () => {
                    cy.removeListener('click', onClickAddTopic);
                    cyedgehandles.disableDrawMode();
                }
            },
            edit: {
                enable: () => {
                    cy.on('click', 'edge', onClickEditLink);
                    cy.on('click', 'node', onClickEditTopic);
                },
                disable: () => {
                    cy.removeListener('click', 'edge', onClickEditLink);
                    cy.removeListener('click', 'node', onClickEditTopic);
                }
            },
            delete: {
                enable: () => {
                    cy.on('click', 'edge', onClickDeleteLink);
                    cy.on('click', 'node', onClickDeleteTopic);
                },
                disable: () => {
                    cy.removeListener('click', 'edge', onClickDeleteLink);
                    cy.removeListener('click', 'node', onClickDeleteTopic);
                }
            }
        };

        (() => {
            let currentMode;
            cyApi.current = {
                setMode: (newMode) => {
                    if (currentMode) {
                        currentMode.disable();
                    }
                    currentMode = MODES[newMode];
                    currentMode.enable();
                }
            }
        })();

        (() => {
            let offset = 0;
            function animateEdges() {
                if (offset >= 100) {
                    offset = 0;
                }
                cy.edges().animate({
                    style: {
                        lineDashOffset: --offset
                    },
                    duration: 500,
                    complete: animateEdges
                });
            }
            animateEdges();
        })();

        return cy.layout({
            name: 'preset',
            positions: presetPositions
        }).run();
    }, [containerRef, generateRef, createTopicElements, managers.topics, openDialog]);

    useEffect(() => {
        if (cyApi.current) {
            cyApi.current.setMode(props.mode);
        }
    }, [props.mode]);

    return <>
        {container}
    </>
}

function QuizTitleDialog({initialValue, onSubmit}) {
    const [value, setValue] = useState(initialValue);
    const {closeDialog} = useDialog();

    return (
        <>
            <DialogTitle>Topic title</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter a title:
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={value}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onSubmit(value);
                            closeDialog();
                        }
                    }}
                    onChange={(e) => setValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="error">Cancel</Button>
                <Button onClick={() => {
                    onSubmit(value);
                    closeDialog();
                }}>Save</Button>
            </DialogActions>
        </>
    );
}

function QuizLinkWeightDialog({initialValue, onSubmit}) {
    const [value, setValue] = useState(initialValue);
    const {closeDialog} = useDialog();

    return (
        <>
            <DialogTitle>Weight</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter a weight (0-100):
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={value}
                    error={value < 0 || value > 100}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onSubmit(value);
                            closeDialog();
                        }
                    }}
                    onChange={(e) => {
                        setValue(parseIntNumber(e.target.value));
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="error">Cancel</Button>
                <Button onClick={() => {
                    onSubmit(value);
                    closeDialog();
                }}>Save</Button>
            </DialogActions>
        </>
    );
}