// noinspection RequiredAttributes

import {NavLink, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Alert,
    Box,
    Checkbox,
    CircularProgress, Divider,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Grow,
    IconButton,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Rating,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {Page} from "../app/Page";
import AppConsts from "../app/AppConsts";
import {FORM, QUESTION} from "../utils/formUtils";
import {ArrowBackOutlined, AssignmentOutlined, EditOutlined} from "@mui/icons-material";
import Button from "@mui/material/Button";
import LeaveConfirm from "../components/LeaveConfirm";
import {DatePicker, DateTimePicker, TimePicker} from "@mui/x-date-pickers";
import {PathGenerator} from "../app/PageRouting";
import {ICON_STYLES, useThemePalette} from "../app/Theme";
import {logError} from "../utils/logging";
import {EmbeddedMarkdown} from "../components/EmbeddedMarkdown";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";
import {UserAutocomplete} from "../components/UserAutocomplete";
import {useDialog} from "../providers/DialogProvider";
import {getFormResponseDialogOptions} from "../form-dashboard/individual/FormResponseDialog";

export function FormCreateResponseMiddleware() {
    return <FormCreateResponse />;
}

function FormCreateResponse() {
    const {formId} = useParams();
    const [viewState, setViewState] = useState({code: "loading"});
    const [form, setForm] = useState({});
    const [isDirty, setIsDirty] = useState(false);
    const keyGenerator = useRef(0);
    const validateTimeout = useRef(null);
    const themePalette = useThemePalette();
    const {openDialog} = useDialog();

    const loadFormData = useCallback((formData) => {
        setForm(() => {
            const form = {
                id: formData.id,
                title: formData.title,
                description: formData.description,
                canEdit: formData.canEdit,
                canViewResponses: formData.canViewResponses,
                canEditResponses: formData.canEdit,
                canDeleteResponses: formData.canEdit,
                open: formData.open,
                theme: formData.theme,
                questions: formData.questions.map(questionData => {
                    const question = {
                        _key: ++keyGenerator.current,
                        id: questionData.id,
                        title: questionData.title,
                        description: questionData.description,
                        type: questionData.type,
                        constraints: questionData.constraints,
                        stepIndex: null,
                        response: {
                            fields: {},
                            errors: [],
                            totalValidationRequest: 0
                        }
                    };
                    if ('choices' in questionData) {
                        question.choices = questionData.choices.map((c, i) => {
                            return {
                                _key: ++keyGenerator.current,
                                title: c,
                                score: questionData.scores[i]
                            };
                        });
                    }
                    switch (question.type) {
                        case QUESTION.TYPES.MEASURE:
                            question.response.fields = {
                                [QUESTION.PRIMARY_FIELD_ID]: {
                                    values: [],
                                    errors: [],
                                    validated: false
                                },
                                [QUESTION.SECONDARY_FIELD_ID]: {
                                    values: [question.choices[0].title],
                                    errors: [],
                                    validated: false
                                }
                            };
                            break;
                        default:
                            question.response.fields = {
                                [QUESTION.PRIMARY_FIELD_ID]: {
                                    values: [],
                                    errors: [],
                                    validated: false
                                }
                            };
                            break;
                    }
                    return question;
                }),
                steps: [],
                currentStepIndex: null,
                response: {
                    errors: [],
                    valid: false
                }
            };

            if (form.questions.length) {
                // One question per step
                form.questions.forEach((question, questionIndex) => {
                    form.steps.push({
                        index: form.steps.length,
                        previousStepIndex: form.steps.length ? form.steps.length - 1 : null,
                        questionsIndexes: [questionIndex],
                        canContinue: false,
                        nextStepIndex: null
                    });
                    question.stepIndex = form.steps.length - 1;
                });
                form.currentStepIndex = 0;
            }

            return form;
        });
    }, [setForm, keyGenerator]);

    const sendResponseRequest = useCallback((form, options = {}) => {
        return new Promise((resolve) => {
            const validatedQuestionsIds = [];

            let postData, url;

            if (options.onlyValidation) {
                url = `${AppConsts.API_PATH}/forms/${formId}/responses/validate`;
                form.steps[form.currentStepIndex].questionsIndexes.forEach(qi => {
                    const q = form.questions[qi];
                    postData = {
                        questionId: q.id,
                        fields: []
                    };
                    for (let id in q.response.fields) {
                        if (q.response.fields.hasOwnProperty(id)) {
                            postData.fields.push({
                                id: id,
                                values: q.response.fields[id].values
                            });
                        }
                    }
                    validatedQuestionsIds.push(q.id);
                });
            } else {
                url = `${AppConsts.API_PATH}/forms/${formId}/responses`;
                postData = {responses: []};

                form.questions.forEach(q => {
                    // If in validation mode only send the currently visible questions
                    if (options.onlyValidation && q.stepIndex !== form.currentStepIndex) {
                        return;
                    }

                    const responsesData = {
                        questionId: q.id,
                        fields: []
                    };
                    for (let id in q.response.fields) {
                        if (q.response.fields.hasOwnProperty(id)) {
                            responsesData.fields.push({
                                id: id,
                                values: q.response.fields[id].values
                            });
                        }
                    }
                    postData.responses.push(responsesData);
                    validatedQuestionsIds.push(q.id);
                });
            }

            httpClient.post(url, postData).then(response => {
                setForm(_form => {
                    const form = {
                        ..._form,
                        response: {
                            ..._form.response,
                            errors: [] // Reset form errors
                        }
                    };

                    const questions = [...form.questions].map(q => {
                        // Reset validated questions errors
                        if (validatedQuestionsIds.includes(q.id)) {
                            q = {...q};
                            q.response = {
                                ...q.response,
                                errors: [],
                                totalValidationRequest: q.response.totalValidationRequest + 1
                            };
                            q.response.fields = {...q.response.fields};
                            for (let id in q.response.fields) {
                                if (q.response.fields.hasOwnProperty(id)) {
                                    q.response.fields[id] = {
                                        ...q.response.fields[id],
                                        errors: [],
                                        validated: true
                                    };
                                }
                            }
                        }
                        return q;
                    });

                    // Set new errors
                    if (response.data.errors) {
                        response.data.errors.forEach(error => {
                            if ('questionId' in error.details) {
                                if (!validatedQuestionsIds.includes(error.details.questionId)) {
                                    return;
                                }
                                const question = questions.find(q => q.id === error.details.questionId);
                                if ('fieldId' in error.details) {
                                    // Question field level error
                                    question.response.fields[error.details.fieldId].errors.push(error.message);
                                } else {
                                    // Question level error
                                    question.response.errors.push(error.message);
                                }
                            } else {
                                // Form level error
                                form.response = {
                                    ...form.response,
                                    errors: [
                                        ...form.response.errors,
                                        error.message
                                    ]
                                };
                            }
                        });
                    }

                    // questions.valid = true if a question has no errors
                    validatedQuestionsIds.forEach(id => {
                        const question = questions.find(q => q.id === id);
                        question.valid = true;
                        if (question.response.errors.length > 0) {
                            question.valid = false;
                        } else {
                            for (let key in question.response.fields) {
                                if (question.response.fields.hasOwnProperty(key)) {
                                    const field = question.response.fields[key];
                                    if (field.errors.length > 0) {
                                        question.valid = false;
                                        break;
                                    }
                                }
                            }
                        }
                    });

                    // form.steps.canContinue = true if all questions are valid and the form too
                    let firstInvalidStepIndex = null;
                    const steps = [...form.steps].map(_step => ({..._step}));
                    steps.forEach((step, stepIndex) => {
                        step.canContinue = step.questionsIndexes.every(i => questions[i].valid)
                            && form.response.errors.length < 1;

                        if (!step.canContinue && firstInvalidStepIndex === null) {
                            firstInvalidStepIndex = stepIndex;
                        }

                        // noinspection JSUnresolvedVariable
                        if ('nextQuestionId' in response.data && step.index === form.currentStepIndex) {
                            if (response.data.nextQuestionId === null) {
                                step.nextStepIndex = null;
                            } else {
                                const _nextStep = steps[form.questions.find(q => q.id === response.data.nextQuestionId).stepIndex];
                                step.nextStepIndex = _nextStep.index;
                                _nextStep.previousStepIndex = step.index;
                            }
                        }
                    });

                    // noinspection JSUnresolvedVariable
                    if (response.data.id) {
                        setViewState({
                            code: "responseCreated",
                            responseId: response.data.id
                        });
                    } else {
                        // If not in validation mode and response was not created then
                        // set the current step to the first one with validation errors
                        if (!options.onlyValidation && firstInvalidStepIndex !== null) {
                            form.currentStepIndex = firstInvalidStepIndex;
                        }
                    }

                    form.questions = questions;
                    form.steps = steps;
                    resolve(form);
                    return form;
                });
            }).catch(e => {
                logError(e);
            })
        });
    }, [formId]);

    const validateResponses = useCallback((form) => {
        clearTimeout(validateTimeout.current);
        validateTimeout.current = setTimeout(() => {
            // noinspection JSIgnoredPromiseFromCall
            sendResponseRequest(form, {
                onlyValidation: true
            });
        }, 250);
    }, [sendResponseRequest]);

    const submitResponse = useCallback((form) => {
        clearTimeout(validateTimeout.current);
        // noinspection JSIgnoredPromiseFromCall
        sendResponseRequest(form);
    }, [sendResponseRequest]);

    const setQuestionResponses = useCallback((questionIndex, input) => {
        setForm(_form => {
            const form = {..._form};
            const questions = [...form.questions];
            const question = {...questions[questionIndex]};

            const field = question.response.fields[input.fieldId];
            if (input.method === 'push') {
                if (!field.values.includes(input.value)) {
                    field.values = [...field.values, input.value];
                }
            } else if (input.method === 'remove') {
                field.values = field.values.filter(v => v !== input.value);
            } else if (input.method === 'set') {
                field.values = input.values;
            }

            if (form.currentStepIndex !== null) {
                const steps = [...form.steps];
                const currentStep = {...steps[form.currentStepIndex]};
                currentStep.canContinue = false;
                steps[form.currentStepIndex] = currentStep;
                form.steps = steps;
            }

            setIsDirty(true);
            validateResponses(form);
            form.questions = questions;
            return form;
        });
    }, [setIsDirty, validateResponses]);

    const gotoPreviousStep = useCallback(() => {
        setForm((form) => {
            if (form.currentStepIndex === null) {
                return;
            }
            const currentStep = form.steps[form.currentStepIndex];
            if (currentStep.previousStepIndex === null) {
                return form;
            }
            return {
                ...form,
                currentStepIndex: currentStep.previousStepIndex
            };
        });
    }, []);

    const gotoNextStep = useCallback((validateIfRequired, formStateParameter) => {
        const set = (form) => {
            if (form.currentStepIndex === null) {
                return form;
            }
            const currentStep = form.steps[form.currentStepIndex];
            if (!currentStep.canContinue) {
                if (validateIfRequired) {
                    sendResponseRequest(form, {
                        onlyValidation: true
                    }).then(formNewState => {
                        gotoNextStep(false, formNewState);
                    });
                }
                return form;
            }
            if (currentStep.nextStepIndex === null) {
                submitResponse(form);
                return form;
            }
            return {
                ...form,
                currentStepIndex: currentStep.nextStepIndex
            };
        };

        if (typeof formStateParameter === "undefined") {
            setForm(form => set(form));
        } else {
            setForm(set(formStateParameter));
        }
    }, [sendResponseRequest, submitResponse]);

    useEffect(() => {
        httpClient.get(`${AppConsts.API_PATH}/forms/${formId}/responding`).then(response => {
            if (response.data.errors) {
                setViewState({
                    code: "loadingError",
                    text: response.data.errors[0].message
                });
            } else {
                loadFormData(response.data.form);
                setViewState({code: "loaded"});
            }
        }).catch((e) => {
            logError(e);
            setViewState({
                code: "loadingError",
                text: t('common.sorry_something_went_wrong')
            });
        });

        setIsDirty(false);
    }, [formId, loadFormData]);
    
    useEffect(() => {
        if (viewState.code === "loaded") {
            themePalette.set(FORM.THEME_2_PALETTE[form.theme]);
        } else {
            themePalette.setDefault();
        }
        return () => {
            themePalette.setDefault();
        }
    }, [form, themePalette, viewState.code]);

    useEffect(() => {
        return () => {
            clearTimeout(validateTimeout.current);
        }
    }, []);

    const backIcon = useMemo(() => <ArrowBackOutlined sx={{mr: 1}} />, []);

    const editIconButton = useMemo(() => (form.id && form.canEdit ?
        <Tooltip title={t('common.edit')}>
            <IconButton component={NavLink} to={PathGenerator.formEditor(form.id)} size="small" sx={{ml: 2}}>
                <EditOutlined sx={ICON_STYLES.icon21} />
            </IconButton>
        </Tooltip> : <></>), [form.canEdit, form.id]);

    if (viewState.code === "loading") {
        return (
            <Page>
                <CircularProgress/>
            </Page>
        );
    }

    if (viewState.code === "loadingError") {
        return (
            <Page>
                <Alert severity="error">{viewState.text}</Alert>
            </Page>
        );
    }

    if (viewState.code === "responseCreated") {
        return (
            <Page title={form.title}>
                <Typography gutterBottom variant="h1" component="h1">
                    {form.title}
                    {editIconButton}
                </Typography>
                <Box sx={{minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{width: '100%', maxWidth: '600px'}}>
                        <Grow in={true}>
                            <Paper variant="outlined" sx={{px: 4, py: 5}}>
                                <Alert severity="success">
                                    <Typography variant="subtitle1">
                                        {t('form_create_response.thanks_your_form_was_submitted')}
                                    </Typography>
                                </Alert>
                                {form.canViewResponses &&
                                    <Button variant="outlined"
                                            fullWidth
                                            startIcon={<AssignmentOutlined/>}
                                            sx={{mt: 3}}
                                            onClick={() => openDialog(getFormResponseDialogOptions(form, viewState.responseId))}>
                                        {t('form_create_response.show_results')}
                                    </Button>}
                                {/*<Typography variant="h3" sx={{mt: 4, mb: 2}}>*/}
                                {/*    {t('form_create_response.share_this_form_on')}*/}
                                {/*</Typography>*/}
                                {/*<ShareButtons url={UrlGenerator.createFormResponse(form.id)} />*/}
                            </Paper>
                        </Grow>
                    </Box>
                </Box>

            </Page>
        );
    }

    if (!form.open) {
        return (
            <Page title={form.title}>
                <Typography gutterBottom variant="h1" component="h1">
                    {form.title}
                    {editIconButton}
                </Typography>
                <Alert severity="info" variant="outlined">This form is not accepting new responses.</Alert>
            </Page>
        );
    }

    if (form.currentStepIndex === null) {
        return (
            <Page title={form.title}>
                <Typography gutterBottom variant="h1" component="h1">
                    {form.title}
                    {editIconButton}
                </Typography>
                <Alert severity="error" variant="outlined">This form has no questions!</Alert>
            </Page>
        );
    }

    return (
        <Page title={form.title}>
            <LeaveConfirm message={t('common.any_unsaved_data_will_be_lost')} when={isDirty} />
            <Typography gutterBottom variant="h1" component="h1">
                {form.title}
                {editIconButton}
            </Typography>
            {form.currentStepIndex === 0 &&
                <EmbeddedMarkdown content={form.description} />
            }
            <Box sx={{minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{width: '100%', maxWidth: '700px'}}>
                    {form.steps[form.currentStepIndex].questionsIndexes.map(questionIndex => {
                        const question = form.questions[questionIndex];
                        return <FormCreateResponseStep
                            key={question._key}
                            question={question}
                            questionIndex={questionIndex}
                            setQuestionResponse={setQuestionResponses} />
                    })}
                    {form.response.errors.map((error, errorKey) =>
                        <Alert severity="error" key={errorKey} sx={{mt: 4}}>{error}</Alert>)}
                    <Stack sx={{mt: 4}} direction="row" spacing={2} justifyContent="space-between">
                        <Button variant="contained" onClick={() => gotoNextStep(true)}>
                            {
                                form.steps[form.currentStepIndex].nextStepIndex !== null
                                    || !form.steps[form.currentStepIndex].canContinue
                                ? t('form.continue')
                                : t('form.complete')
                            }
                        </Button>
                        {form.steps[form.currentStepIndex].previousStepIndex !== null &&
                        <Button variant="outlined" onClick={gotoPreviousStep}>
                            {backIcon}
                            {t('common.back')}
                        </Button>}
                    </Stack>
                </Box>
            </Box>
        </Page>
    );
}

function FormCreateResponseStep(props) {
    const question = props.question;
    const questionIndex = props.questionIndex;
    const onChangeResponse = props.setQuestionResponse;

    return (
        <Grow in={true}>
            <Paper variant="outlined" sx={{mt: 3, px: 4, py: 5}}>
                <Typography gutterBottom variant="h2" component="h2">
                    {question.title}
                </Typography>
                {question.description &&
                    <EmbeddedMarkdown content={question.description} />}
                <FormCreateResponseInputSet
                    question={question}
                    questionIndex={questionIndex}
                    onChangeResponse={onChangeResponse} />
                {question.response.errors.map((error, errorKey) =>
                    <Alert severity="error" key={errorKey} sx={{mt: 4}}>{error}</Alert>)}
            </Paper>
        </Grow>
    );
}

function FormCreateResponseInputHelper({question, fieldId}) {
    const field = question.response.fields[fieldId];

    const constraints = [];
    question.constraints.forEach(constraintMessage => {
        const hasError = field.errors.includes(constraintMessage);
        constraints.push({
            _key: constraintMessage,
            _animKey: hasError ? question.response.totalValidationRequest : 0,
            message: constraintMessage,
            severity: hasError ? "error" : (field.validated ? "success" : "info")
        });
    });
    field.errors.forEach(message => {
        if (!constraints.find(c => c.message === message)) {
            constraints.unshift({
                _key: message,
                _animKey: question.response.totalValidationRequest,
                message: message,
                severity: "error"
            });
        }
    });

    if (!question.constraints.length) {
        return <></>
    }

    return (
        <Box sx={{mt: 2}}>
            {constraints.map(constraint =>
                <Alert key={constraint._key} severity={constraint.severity} variant="outlined" sx={{p: 0, border: 0}}>
                    <Fade key={constraint._animKey} timeout={500} in={true}>
                        <Box>
                            {constraint.message}
                            {constraint.severity === "success" && " - ok"}
                        </Box>
                    </Fade>
                </Alert>)}
        </Box>
    );
}

function FormCreateResponseInputSet({question, questionIndex, onChangeResponse}) {
    if (question.type === QUESTION.TYPES.SINGLE_CHOICE) {
        return <>
            <FormControl
                error={question.response.fields[QUESTION.PRIMARY_FIELD_ID].errors.length > 0}
                variant="standard"
                component="fieldset"
                sx={{mt: 2, width: 1}}>
                <RadioGroup
                    onChange={e =>
                        onChangeResponse(questionIndex, {
                            fieldId: QUESTION.PRIMARY_FIELD_ID,
                            values: [e.target.value],
                            method: 'set'
                        })
                    }>
                    {(question.choices).map(choice => {
                        const checked = question.response.fields[QUESTION.PRIMARY_FIELD_ID].values.includes(choice.title);
                        return <>
                                <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between">
                                    <FormControlLabel
                                        key={choice._key}
                                        value={choice.title}
                                        control={<Radio/>}
                                        label={choice.title}
                                        checked={checked}/>
                                    <Typography component="span" color={checked ? "primary" : "text.secondary"}>
                                        {choice.score > 0 ? "+" + choice.score : choice.score}
                                    </Typography>
                                </Stack>
                                <Divider sx={{opacity: 0.7}}></Divider>
                            </>;
                        }
                    )}
                </RadioGroup>
            </FormControl>
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    if (question.type === QUESTION.TYPES.MULTIPLE_CHOICE) {
        // noinspection JSUnresolvedVariable
        return <>
            <FormControl
            sx={{mt: 2, width: 1}}
            error={question.response.fields[QUESTION.PRIMARY_FIELD_ID].errors.length > 0}
            variant="standard"
            component="fieldset">
                <FormGroup
                    onChange={e =>
                        onChangeResponse(questionIndex, {
                            fieldId: QUESTION.PRIMARY_FIELD_ID,
                            value: e.target.value,
                            method: e.target.checked ? 'push' : 'remove'
                        })
                    }>
                    {(question.choices).map(choice => {
                        const checked = question.response.fields[QUESTION.PRIMARY_FIELD_ID].values.includes(choice.title);
                        return <>
                                <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between">
                                    <FormControlLabel
                                        key={choice._key}
                                        value={choice.title}
                                        control={<Checkbox/>}
                                        label={choice.title}
                                        checked={checked}/>
                                    <Typography component="span" color={checked ? "primary" : "text.secondary"}>
                                        {choice.score > 0 ? "+" + choice.score : choice.score}
                                    </Typography>
                                </Stack>
                                <Divider sx={{opacity: 0.7}}></Divider>
                            </>;
                        }
                    )}
                </FormGroup>
            </FormControl>
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    if (question.type === QUESTION.TYPES.TIME) {
        return <>
            <TimePicker
            sx={{mt: 2}}
            value={new Date(question.response.fields[QUESTION.PRIMARY_FIELD_ID].values?.[0])}
            onChange={newValue =>
                onChangeResponse(questionIndex, {
                    fieldId: QUESTION.PRIMARY_FIELD_ID,
                    values: [newValue],
                    method: 'set'
                })
            }
            slotProps={{ textField: { error: question.response.fields[QUESTION.PRIMARY_FIELD_ID].errors.length > 0 } }}
            />
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    if (question.type === QUESTION.TYPES.DATETIME) {
        return <>
            <DateTimePicker
            sx={{mt: 2}}
            value={new Date(question.response.fields[QUESTION.PRIMARY_FIELD_ID].values?.[0])}
            onChange={newValue =>
                onChangeResponse(questionIndex, {
                    fieldId: QUESTION.PRIMARY_FIELD_ID,
                    values: [newValue],
                    method: 'set'
                })
            }
            slotProps={{ textField: { error: question.response.fields[QUESTION.PRIMARY_FIELD_ID].errors.length > 0 } }}
            />
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    if (question.type === QUESTION.TYPES.DATE) {
        return <>
            <DatePicker
            sx={{mt: 2}}
            value={new Date(question.response.fields[QUESTION.PRIMARY_FIELD_ID].values?.[0])}
            onChange={newValue =>
                onChangeResponse(questionIndex, {
                    fieldId: QUESTION.PRIMARY_FIELD_ID,
                    values: [newValue],
                    method: 'set'
                })
            }
            slotProps={{ textField: { error: question.response.fields[QUESTION.PRIMARY_FIELD_ID].errors.length > 0 } }}
            />
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    if (question.type === QUESTION.TYPES.MULTILINE_TEXT) {
        return <>
            <TextField
            sx={{mt: 2}}
            variant="outlined"
            value={question.response.fields[QUESTION.PRIMARY_FIELD_ID].values?.[0]||""}
            onChange={e =>
                onChangeResponse(questionIndex, {
                    fieldId: QUESTION.PRIMARY_FIELD_ID,
                    values: [e.target.value],
                    method: 'set'
                })
            }
            error={question.response.fields[QUESTION.PRIMARY_FIELD_ID].errors.length > 0}
            minRows={3}
            multiline
            fullWidth/>
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    if (question.type === QUESTION.TYPES.TEXT
        || question.type === QUESTION.TYPES.URL
        || question.type === QUESTION.TYPES.EMAIL) {
        return <>
            <TextField
            sx={{mt: 2}}
            variant="outlined"
            label={
                question.type === QUESTION.TYPES.URL
                    ? "https://" :
                    question.type === QUESTION.TYPES.EMAIL
                        ? "your@email.com" : ""}
            value={question.response.fields[QUESTION.PRIMARY_FIELD_ID].values?.[0]||""}
            onChange={e =>
                onChangeResponse(questionIndex, {
                    fieldId: QUESTION.PRIMARY_FIELD_ID,
                    values: [e.target.value],
                    method: 'set'
                })
            }
            error={question.response.fields[QUESTION.PRIMARY_FIELD_ID].errors.length > 0}
            fullWidth/>
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    if (question.type === QUESTION.TYPES.MEASURE) {
        return <>
            <Grid container sx={{mt: 2, width: 1}} alignItems="center" spacing={2}>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        placeholder="0"
                        variant="outlined"
                        value={question.response.fields[QUESTION.PRIMARY_FIELD_ID].values?.[0]||""}
                        onChange={e =>
                            onChangeResponse(questionIndex, {
                                fieldId: QUESTION.PRIMARY_FIELD_ID,
                                values: [e.target.value],
                                method: 'set'
                            })
                        }
                        error={question.response.fields[QUESTION.PRIMARY_FIELD_ID].errors.length > 0}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        fullWidth
                        variant="outlined"
                        onChange={e =>
                            onChangeResponse(questionIndex, {
                                fieldId: QUESTION.SECONDARY_FIELD_ID,
                                values: [e.target.value],
                                method: 'set'
                            })
                        }
                        value={question.response.fields[QUESTION.SECONDARY_FIELD_ID].values?.[0]||""}
                        error={question.response.fields[QUESTION.SECONDARY_FIELD_ID].errors.length > 0}>
                        {question.choices.map(choice => {
                            return (
                                <MenuItem value={choice.title} key={choice._key}>
                                    {choice.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
            </Grid>
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    if (question.type === QUESTION.TYPES.RATING) {
        let value = question.response.fields[QUESTION.PRIMARY_FIELD_ID].values?.[0] || 0;

        return <>
            <Stack direction="row" alignItems="center" spacing={2} sx={{mt: 2}}>
                <Rating
                    size="large"
                    precision={0.5}
                    value={value}
                    onChange={(event, newValue) => {
                        onChangeResponse(questionIndex, {
                            fieldId: QUESTION.PRIMARY_FIELD_ID,
                            values: [newValue],
                            method: 'set'
                        });
                    }}
                />
                <Box>
                    {value} / 5
                </Box>
            </Stack>
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    if (question.type === QUESTION.TYPES.PATIENT) {
        return <>
            <Box sx={{mt: 2}}>
                <UserAutocomplete onChange={(newValue) => {
                    onChangeResponse(questionIndex, {
                        fieldId: QUESTION.PRIMARY_FIELD_ID,
                        values: newValue ? [newValue.id] : [],
                        method: 'set'
                    });
                }} />
            </Box>
            <FormCreateResponseInputHelper question={question} fieldId={QUESTION.PRIMARY_FIELD_ID} />
        </>;
    }

    return <></>
}

