import {createTheme, ThemeProvider} from "@mui/material/styles";
import {blue, blueGrey, deepOrange, green, indigo, lightGreen, orange, purple, red, teal} from '@mui/material/colors';
import React, {createContext, useContext, useMemo, useState} from "react";
import {makeStyles} from "@mui/styles";

const THEME_PALETTES = {
    default: {
        main: '#304ffe',
        dark: '#2137b1',
        light: '#5972fe',
        background: '#f3f6f9'
    },
    blue: {
        main: blue[600],
        dark: blue[700],
        light: blue[400],
        background: '#ebf1f5'
    },
    green: {
        main: green[600],
        dark: green[700],
        light: green[400],
        background: '#ecf1ec'
    },
    orange: {
        main: orange[600],
        dark: orange[700],
        light: orange[400],
        background: '#f6f1e9'
    },
    purple: {
        main: purple[600],
        dark: purple[700],
        light: purple[400],
        background: '#efeaf0'
    },
    red: {
        main: red[600],
        dark: red[700],
        light: red[400],
        background: '#f9f1f2'
    },
    teal: {
        main: teal[600],
        dark: teal[700],
        light: teal[400],
        background: '#e5edec'
    },
    lightGreen: {
        main: lightGreen[600],
        dark: lightGreen[700],
        light: lightGreen[400],
        background: '#f1f3ed'
    },
    blueGrey: {
        main: blueGrey[600],
        dark: blueGrey[700],
        light: blueGrey[400],
        background: '#eceff1'
    },
    deepOrange: {
        main: deepOrange[600],
        dark: deepOrange[700],
        light: deepOrange[400],
        background: '#f5eeed'
    },
    indigo: {
        main: indigo[600],
        dark: indigo[700],
        light: indigo[400],
        background: '#e8eaf6'
    }
};

const DEFAULT_THEME_OPTIONS = {
    palette: {
        type: 'light',
        primary: {
            main: THEME_PALETTES.default.main,
            dark: THEME_PALETTES.default.dark,
            light: THEME_PALETTES.default.light,
        },
        secondary: {
            main: THEME_PALETTES.red.main,
            dark: THEME_PALETTES.red.dark,
            light: THEME_PALETTES.red.light,
        },
        background: {
            default: '#fff',
            paper: '#fff',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        }
    },
    typography: {
        fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
        fontSize: 15,
        h1: {
            fontSize: '2rem',
            fontWeight: 700
        },
        h2: {
            fontSize: '1.4rem',
            fontWeight: 500
        },
        h3: {
            fontSize: '1.2rem',
            fontWeight: 500
        },
        h4: {
            fontSize: '1.15rem',
            fontWeight: 500
        },
        h5: {
            fontSize: '1.1rem',
            fontWeight: 400
        },
        h6: {
            fontSize: '1.0rem',
            fontWeight: 400
        },
        subtitle1: {
            fontSize: '1.1rem'
        },
        subtitle2: {
            fontSize: '1rem'
        }
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: '12px'
                },
                elevation1: {
                    boxShadow: '1px 1px 1px hsla(231, 44%, 90%, 0.7)'
                },
                elevation2: {
                    boxShadow:
                        '1px 2px 2px hsla(231, 44%, 90%, 0.333),' +
                        '2px 4px 4px hsla(231, 44%, 90%, 0.333),' +
                        '3px 6px 6px hsla(231, 44%, 90%, 0.333)'
                },
                elevation3: {
                    boxShadow:
                        '1px 2px 2px hsla(231, 44%, 90%, 0.5),' +
                        '2px 4px 4px hsla(231, 44%, 90%, 0.5),' +
                        '4px 8px 8px hsla(231, 44%, 90%, 0.5),' +
                        '8px 16px 16px hsla(231, 44%, 90%, 0.5),' +
                        '16px 32px 32px hsla(231, 44%, 90%, 0.5)'
                },
                elevation12: { // red
                    boxShadow:
                        '1px 2px 2px ' + red[200] + ',' +
                        '2px 4px 4px ' + red[200] + ',' +
                        '3px 6px 6px ' + red[200] + ''
                },
                outlined: {
                    border: '1px solid rgb(218, 220, 224)'
                }
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    boxShadow: '1px 1px 1px red !important'
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6'
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&:before': {
                        borderBottomColor: 'rgba(0, 0, 0, 0.2)'
                    }
                }
            }
        },
        MuiDivider: {
            componentsProps: {
                root: {
                    borderColor: 'rgba(0, 0, 0, 0.08)'
                }
            }
        }
    }
};

const Theme = createTheme(DEFAULT_THEME_OPTIONS);

const ThemePaletteContext = createContext({
    set: () => {},
    setDefault: () => {}
});

const useThemePalette = () => {
    return useContext(ThemePaletteContext);
};

function ThemePaletteProvider({children}) {
    const DEFAULT_PALETTE_ID = 'default';
    const [paletteId, setPaletteId] = useState(DEFAULT_PALETTE_ID);

    const providerValue = useMemo(() => ({
        set: (paletteId) => {
            setPaletteId(paletteId);
        },
        setDefault: () => {
            setPaletteId(DEFAULT_PALETTE_ID);
        }
    }), []);

    const theme = useMemo(() => {
        if (paletteId === DEFAULT_PALETTE_ID) {
            return Theme;
        }

        const palette = THEME_PALETTES[paletteId];
        return createTheme({
            ...DEFAULT_THEME_OPTIONS,
            palette: {
                ...DEFAULT_THEME_OPTIONS.palette,
                primary: {
                    main: palette.main,
                    dark: palette.dark,
                    light: palette.light
                },
                background: {
                    ...DEFAULT_THEME_OPTIONS.palette.background,
                    default: palette.background
                }
            },
            components: {
                ...DEFAULT_THEME_OPTIONS.components,
                MuiPaper: {
                    ...DEFAULT_THEME_OPTIONS.components.MuiPaper,
                    styleOverrides: {
                        ...DEFAULT_THEME_OPTIONS.components.MuiPaper.styleOverrides,
                        elevation1: {
                            boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.7)'
                        },
                        elevation2: {
                            boxShadow:
                                '1px 2px 2px rgba(0, 0, 0, 0.02),' +
                                '2px 4px 4px rgba(0, 0, 0, 0.02),' +
                                '3px 6px 6px rgba(0, 0, 0, 0.02)'
                        },
                        elevation3: {
                            boxShadow:
                                '1px 2px 2px rgba(0, 0, 0, 0.025),' +
                                '2px 4px 4px rgba(0, 0, 0, 0.025),' +
                                '4px 8px 8px rgba(0, 0, 0, 0.025),' +
                                '8px 16px 16px rgba(0, 0, 0, 0.025),' +
                                '16px 32px 32px rgba(0, 0, 0, 0.025)'
                        },
                    }
                }
            }
        });
    }, [paletteId]);

    return (
        <ThemePaletteContext.Provider value={providerValue}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ThemePaletteContext.Provider>
    );
}

const ICON_STYLES = {
    icon16: {fontSize: 16},
    icon16Left: {fontSize: 16, mr: 2},
    icon21: {fontSize: 21},
    icon21Left: {fontSize: 21, mr: 2}
};

const TAB_ERROR_STYLE = {
    color: red[500]
};

const autocompleteStyles = makeStyles({
    paper: {
        boxShadow: DEFAULT_THEME_OPTIONS.components.MuiPaper.styleOverrides.elevation3.boxShadow
    }
});

export {ThemePaletteProvider, useThemePalette, autocompleteStyles, Theme, THEME_PALETTES, ICON_STYLES, TAB_ERROR_STYLE};