import {useCallback, useEffect, useRef} from "react";
import {CircularProgress, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useUser} from "./UserProvider";
import Paper from "@mui/material/Paper";
import AppConsts from "../app/AppConsts";
import {logError} from "../utils/logging";
import {Page} from "../app/Page";
import {useHistory} from "react-router";
import {PathsMap} from "../app/PageRouting";
import {httpClient} from "../utils/httpClient";
import {t} from "i18next";

export function SignOut() {
    const user = useUser();
    const history = useHistory();

    const redirected = useRef(false);
    const redirectWithTimeout = useCallback(() => {
        setTimeout(() => {
            history.push(PathsMap.HOME);
        }, 1000);
    }, [history]);

    useEffect(() => {
        if (redirected.current) {
            return;
        }
        redirected.current = true;
        httpClient.post(`${AppConsts.API_PATH}/users/auth/signout`).then(() => {
            user.set(null);
            redirectWithTimeout();
        }).catch((e) => {
            logError(e);
            user.set(null);
            redirectWithTimeout();
        });
    }, [user, redirectWithTimeout, redirected]);

    return (
        <Page title={t('sign_out.sign_out_button')} maxWidth="sm" centered>
            <Paper sx={{p: 4}} variant="outlined">
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <CircularProgress size={24}/>
                    <Typography>
                        {t('sign_out.signed_out_please_wait')}
                    </Typography>
                </Stack>
            </Paper>
        </Page>
    );
}