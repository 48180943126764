import {useCallback, useRef} from "react";

const useIdGenerator = () => {
    const generatedIds = useRef([]);
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    const generateId = useCallback((length) => {
        if (typeof length !== 'number') {
            length = 6;
        }
        let thisId = '';
        for (let i = 0; i < length; i++) {
            thisId += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        if (generatedIds.current.includes(thisId)) {
            return generateId(length);
        }
        generatedIds.current.push(thisId);
        return thisId;
    }, []);

    const addGeneratedId = useCallback((id) => {
        generatedIds.current.push(id);
        return id;
    }, []);

    return {generateId, addGeneratedId};
};

export default useIdGenerator;